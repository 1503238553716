
import { webClient } from "@api/web/WebClient";
import LoadingBlob from "@components/LoadingBlob";
import Logo from "@components/Logo";
import AnimatedCheckmark from "@components/web/AnimatedCheckmark";
import { withWebLayout } from "@components/web/Decorators";
import { FrontPageLayout } from "@components/web/layouts/FrontPageLayout";
import { useAuthenticatedUser } from "@hooks/authentication";
import { useLocalization } from "@hooks/localization";
import { useQueryParameters } from "@hooks/navigation";
import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import { TransactionState } from "@reshopper/web-client";
import i18n from "@utils/i18n";
import { delay } from "@utils/miscellaneous";
import { ReactNode, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Trans } from "react-i18next";
import styles from "./succes.module.scss";

type QueryParameters = {
    transactionId: string;
    id: string;
    invoice: string;
    customer: string;
};

export default withWebLayout(function Succes() {
    const t = useLocalization();
    const params = useQueryParameters<QueryParameters>();
    const [transactionState, setTransactionState] = useState<TransactionState | undefined | null>(undefined);
    const stringifyParams = JSON.stringify(params).replace('{', '').replace('}', '').replaceAll('"', '');
    const authenticatedUser = useAuthenticatedUser();

    useEffect(() => {
        const getUserLanguage = async () => {
            try {
                const languageResponse = await webClient().webUsersLanguageGet();

                const language = languageResponse.language;
                if (i18n.language !== language) {
                    i18n.changeLanguage(language);
                }

            } catch (error) {
                console.error(error);
            }
        };
        getUserLanguage();
    }, [authenticatedUser]);

    useEffect(() => {
        const startPollingForTransaction = async () => {
            await delay(5000);
            if (!!transactionState || transactionState === null) {
                return;
            }

            const interval = setInterval(async () => {
                if (!!params.transactionId) {
                    try {
                        const response = await webClient().webTransactionsRetailVoucherState(params.transactionId);
                        if (response.transactionState) {
                            setTransactionState(response.transactionState);
                            return () => clearInterval(interval);
                        }
                    }
                    catch (ex) {
                        return;
                    }
                }
                setTimeout(() => clearInterval(interval), 60000);
            }, 100);
            return () => clearInterval(interval);
        }

        startPollingForTransaction();

    }, [params.transactionId, transactionState]);


    if (transactionState === undefined) {
        return <SuccesPageTemplate>
            <Box>
                <Box style={{ display: "inline-flex" }}><LoadingBlob /></Box>
                <Typography style={{ marginTop: "15px", fontWeight: "bold" }}>
                    {t("GiftCard_SuccessPage_PleaseWait")}
                </Typography>
            </Box>
        </SuccesPageTemplate>
    }

    if (transactionState === null || transactionState === "cancelled") {
        return <SuccesPageTemplate>
            <Box>
                <Box style={{ fontSize: "40px" }}>🫣</Box>
                <Typography style={{ marginTop: "15px", fontWeight: "bold" }}>
                    {t("GiftCard_SuccessPage_Error_Title")}
                </Typography>
                <Typography>
                    <Trans
                        i18nKey="GiftCard_SuccessPage_Error_Text"
                        values={{
                            link: `mailto:support@reshopper.com?subject=Fejl ved køb af gavekort!&body=Til Reshopper support.%0D%0A%0D%0ADer opstod en fejl ved køb af et gavekort.%0D%0A%0D%0A%0D%0A(Info:${stringifyParams})`
                        }}
                        components={{ a: <a /> }} />
                </Typography>
            </Box>
        </SuccesPageTemplate>
    }

    return <SuccesPageTemplate>
        <Box>
            <Box style={{ display: "inline-flex", minHeight: "60px" }}>
                <AnimatedCheckmark />
            </Box>
            <Typography variant="h6" style={{ marginTop: "15px", fontSize: "1.1rem", marginBottom: "10px", fontWeight: "bold" }}>
                {t("GiftCard_SuccessPage_Success_Title")}
            </Typography>
            <Typography>
                <Trans
                    i18nKey="GiftCard_SuccessPage_Success_Text"
                    components={{ span: <span style={{ whiteSpace: "nowrap" }} /> }} />
            </Typography>
            <Box className={styles.passOnGiftCard}>
                <Box style={{ maxWidth: "470px", margin: "auto", textAlign: "left" }}>
                    <Typography variant="h6" style={{ fontSize: "1.1rem" }}>
                        {t("GiftCard_SuccessPage_Success_Box_Headline")}
                    </Typography>
                    <Typography>
                        <Trans
                            i18nKey="GiftCard_SuccessPage_Success_Box_Text"
                            components={{ span: <span style={{ whiteSpace: "nowrap" }} /> }} />
                    </Typography>
                </Box>
            </Box>
            <Typography style={{ marginTop: "20px", fontSize: "0.9rem" }}>
                <Trans
                    i18nKey="GiftCard_SuccessPage_Success_Text_ReadMore"
                    values={{
                        link1: "https://reshopper.com/dk/gavekort",
                        link2: `mailto:support@reshopper.com?subject=Køb af gavekort!&body=Til Reshopper support.%0D%0A%0D%0A%0D%0A%0D%0A%0D%0A(Info:${stringifyParams})`
                    }}
                    components={{ a: <a /> }} />
            </Typography>

            <Logo
                variant="icon-text-brown-horizontal"
                href="/"
                className={styles.logo} />
            <Typography className={styles.info}>
                Reshopper ApS<br />
                Inge Lehmanns Gade 10<br />
                8000 Aarhus <span style={{ fontSize: "0.5em" }}>&bull;</span> Danmark<br />
                CVR. 34711763
            </Typography>
        </Box>
    </SuccesPageTemplate>
});


function SuccesPageTemplate(props: {
    children: ReactNode
}): JSX.Element {
    const t = useLocalization();

    return <FrontPageLayout removeBlueBodyBackground withoutAppBar>
        <Helmet
            title={"Reshopper gavekort"}>
            <meta name="description" content={"Med et gavekort fra Reshopper kan du give en meningsfuld gave, der glæder både børnefamilien, miljøet og pengepungen"} />
            <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <Container className={styles.successPage}>
            <Container className={styles.content}>
                <>{props.children}</>
            </Container>
        </Container>
    </FrontPageLayout>
}
